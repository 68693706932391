import moment from "moment"
import { hideGlobalLoader, showGlobalLoader } from "./GlobalLoaderHelper"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const downloadFilesBase64 = (base, filename) => {
  const downloadLink = document.createElement("a")
  downloadLink.href = base
  downloadLink.download = filename
  downloadLink.click()
}

export const openFileInNewTab = (base, name) => {
  const pdfWindow = window.open("", "_blank")
  pdfWindow.document.title = name
  if (base.includes("image/")) {
    return pdfWindow.document.write(`<img style="width:100%;" src="${base}" />`)
  }
  pdfWindow.document.write(`<iframe width='100%' height='100%' src='${base}'></iframe>`)
}

export function isPromise(value) {
  return value instanceof Promise
}

export const generateGUID = () => {
  let result = ''
  const characters = '0123456789abcdef'
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      result += '-'
    }
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export const generateGUIDWithSeed = (seed) => {
  function seededRandom(seed) {
    const x = Math.sin(seed) * 10000
    return x - Math.floor(x)
  }

  function s4(seed) {
    return Math.floor((1 + seededRandom(seed)) * 0x10000)
      .toString(16)
      .substring(1)
  }

  // Incrementando a semente para cada parte do GUID
  const part1 = s4(seed)
  const part2 = s4(seed + 1)
  const part3 = s4(seed + 2)
  const part4 = s4(seed + 3)
  const part5 = s4(seed + 4) + s4(seed + 5) + s4(seed + 6)

  // Construindo o GUID com template literals
  return `${part1}-${part2}-${part3}-${part4}-${part5}`
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getToken = () => JSON.parse(localStorage.getItem('accessToken'))

export const getRefreshToken = () => JSON.parse(localStorage.getItem('refreshToken'))

export const getTokenExpiration = () => JSON.parse(localStorage.getItem('tokenExpiresIn'))

export const getRefreshTokenExpiration = () => JSON.parse(localStorage.getItem('refreshTokenExpiresIn'))

export const getAuthTokenAuthorization = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  return userData?.authTokenAuthorization
}

export const getLocalStorageSessoes = (credencialId) => {
  const dadosNoLocalStorage = JSON.parse(localStorage.getItem('sessoes'))
  if (dadosNoLocalStorage === null) { return null }
  const resultadoFiltrado = dadosNoLocalStorage.find(item => {
    return item.credencialId === credencialId
  })
  return resultadoFiltrado
}

export const classificacaoOptions = [
  { value: 'O', label: 'Consultório' },
  { value: 'C', label: 'Clínica' },
  { value: 'H', label: 'Hospital' },
  { value: 'L', label: 'Laboratório' },
  { value: 'I', label: 'Centro de Imagem' },
  { value: 'P', label: 'Parceiro B2B' }
]

export const uf_conselhos_options = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AM", value: "AM" },
  { label: "AP", value: "AP" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MG", value: "MG" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "PR", value: "PR" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "RS", value: "RS" },
  { label: "SC", value: "SC" },
  { label: "SE", value: "SE" },
  { label: "SP", value: "SP" },
  { label: "TO", value: "TO" }
]

export const setLocalStorageSessoes = (storage) => {
  if (localStorage.getItem('sessoes') === null || localStorage.getItem('sessoes') === undefined) {
    const itens = []
    itens.push(storage)
    localStorage.setItem('sessoes', JSON.stringify(itens))
  } else {
    const dadosNoLocalStorage = JSON.parse(localStorage.getItem('sessoes'))
    const resultadoFiltrado = dadosNoLocalStorage.find(item => {
      return item.credencialId === storage.credencialId
    })
    if (!resultadoFiltrado) {
      const atuais = JSON.parse(localStorage.getItem('sessoes'))
      atuais.push(storage)
      localStorage.setItem('sessoes', JSON.stringify(atuais))
    }
  }
}

export const retornaAjusteDataNascimento = (datanascimento) => {
  if (datanascimento === undefined || datanascimento === null || datanascimento === '') {
    return null
  } else {
    const parts = datanascimento.split("/")
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]))
  }
}

export const updateLocalStorageSessoes = (sessao, credencialId) => {
  const dadosNoLocalStorage = JSON.parse(localStorage.getItem('sessoes'))
  if (dadosNoLocalStorage?.length > 0) {
    const resultadoFiltrado = dadosNoLocalStorage.find(item => {
      return item.credencialId === credencialId
    })
    if (resultadoFiltrado) {

      resultadoFiltrado.sessao = sessao
      resultadoFiltrado.credencialId = credencialId
      const outrosItens = JSON.parse(localStorage.getItem('sessoes'))
        .filter(item => item.credencialId !== credencialId)

      outrosItens.push(resultadoFiltrado)
      setTimeout(() => { localStorage.setItem('sessoes', JSON.stringify(outrosItens)) }, 100)
    }

  }
}

export const handleLogin = data => {
  localStorage.setItem('userData', JSON.stringify(data.userData))
  localStorage.setItem('accessToken', JSON.stringify(data.accessToken))
  localStorage.setItem('refreshToken', JSON.stringify(data.refreshToken))
  localStorage.setItem('tokenExpiresIn', JSON.stringify(data.tokenExpiresIn))
  localStorage.setItem('refreshTokenExpiresIn', JSON.stringify(data.refreshTokenExpiresIn))
}

export const handleLogout = () => {
  localStorage.removeItem('userData')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('sessoes')
  localStorage.removeItem('tokenExpiresIn')
  localStorage.removeItem('refreshTokenExpiresIn')
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'client') return '/home'
  if (userRole === 'supervisor') return '/supervisor/home'
  if (userRole === 'operador') return '/operador/home'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  },
  spacing: {
    ...theme.spacing,
    fontSize: '14px'
  }
})

export function resetCepFormat(cep) {
  return cep?.replace('.', '').replace('-', '')
}

export function resetCnpjFormat(cnpj) {
  return cnpj?.replaceAll('.', '').replace('/', '').replace('-', '')
}

export function resetCpfCnpjFormat(cnpj) {
  return cnpj?.replaceAll('.', '').replace('/', '').replace('-', '')
}

export function resetCpfFormat(cpf) {
  return cpf?.replaceAll('.', '').replace('-', '')
}

export const getCep = async cep => {
  const raw_cep = resetCepFormat(cep)

  if (raw_cep.length >= 8) {
    try {
      const reqParams = {
        method: 'GET'
      }
      const url_completa = new URL(`https://viacep.com.br/ws/${raw_cep}/json/`).toString()

      const resp = await fetch(url_completa, reqParams)
      const json = await resp.json()
      return json
    } catch (error) {
      console.log('error', error)
    }
  }
}

const setHeaders = (content_type) => {
  const headers = new Headers()
  if (content_type !== '') headers.append('Content-Type', content_type)
  return headers
}

export const obterRefreshToken = async () => {
  try {
    showGlobalLoader()
    const params = {
      refreshToken: JSON.parse(localStorage.getItem('refreshToken'))
    }
    const url_completa = `${process.env.REACT_APP_API_URL}/v1/auth/refresh-token`
    const headers = setHeaders('application/json, text/plain')
    const reqParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    }
    const resp = await fetch(url_completa, reqParams)
    const json = await resp.json()
    if (json) {
      if (json?.mensagens) {
        handleLogout()
        window.location.href = '/login'
        hideGlobalLoader()
        return
      }
      localStorage.setItem('accessToken', JSON.stringify(json.accessToken))
      localStorage.setItem('refreshToken', JSON.stringify(json.refreshToken))
      localStorage.setItem('tokenExpiresIn', JSON.stringify(moment().add(json.expiresIn, 'seconds').toISOString()))
      localStorage.setItem('refreshTokenExpiresIn', JSON.stringify(moment().add(json.refreshTokenExpiresIn, 'seconds').toISOString()))
      window.location.reload()
      hideGlobalLoader()
    } else {
      handleLogout()
      window.location.href = '/login'
      hideGlobalLoader()
    }
    // return json
  } catch (error) {
    handleLogout()
    window.location.href = '/login'
    hideGlobalLoader()
    console.log('error', error)
  }
}

export function removerCaracteres(valor) {
  return valor
    ? String(valor)
      .replace(/[^\w\s]/gi, "")
      .trim()
    : valor
}

export function formatarValorSalvar(valor) {
  return valor
    ? parseFloat(
      String(valor)
        ?.replace(/[^0-9,.]/g, "")
        ?.replace(".", "")
        ?.replace(",", ".")
        ?.trim()
    )
    : valor
}

export function formataPercentual(valor, casasDecimais = 2) {
  return `${valor?.toLocaleString("pt-br", {
    currency: "BRL",
    maximumFractionDigits: casasDecimais
  })}%`
}

export const formatCurrency = (value, precision = 2) => {
  let translation = localStorage.getItem('translation')
  let currency = 'BRL'

  if (translation) {
    translation = translation.replace('_', '-')
    if (translation === 'en') currency = 'USD'
    else if (translation === 'es') currency = 'EUR'
  } else translation = 'pt-BR'

  return new Intl.NumberFormat(translation, {
    style: 'currency',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    currency
  }).format(value)
}

export const findIndexInArrayById = (list, reference, param) => {
  let index = -1
  for (let i = 0; i < list.length; i++) {
    if (list[i] && list[i][reference] === param) {
      index = i
      break
    }
  }
  return index
}

export const phoneMasks = {
  AC: "+247-####",
  AD: "+376-###-###",
  AE: "+971-5#-###-####",
  AE: "+971-#-###-####",
  AF: "+93-##-###-####",
  AG: "+1(268)###-####",
  AI: "+1(264)###-####",
  AL: "+355(###)###-###",
  AM: "+374-##-###-###",
  AN: "+599-###-####",
  AN: "+599-###-####",
  AN: "+599-9###-####",
  AO: "+244(###)###-###",
  AQ: "+672-1##-###",
  AR: "+54(###)###-####",
  AS: "+1(684)###-####",
  AT: "+43(###)###-####",
  AU: "+61-#-####-####",
  AW: "+297-###-####",
  AZ: "+994-##-###-##-##",
  BA: "+387-##-#####",
  BA: "+387-##-####",
  BB: "+1(246)###-####",
  BD: "+880-##-###-###",
  BE: "+32(###)###-###",
  BF: "+226-##-##-####",
  BG: "+359(###)###-###",
  BH: "+973-####-####",
  BI: "+257-##-##-####",
  BJ: "+229-##-##-####",
  BM: "+1(441)###-####",
  BN: "+673-###-####",
  BO: "+591-#-###-####",
  BR: "+55(##) ####-####",
  BR: "+55(##) 7###-####",
  BR: "+55(##) 9####-####",
  BS: "+1(242)###-####",
  BT: "+975-17-###-###",
  BT: "+975-#-###-###",
  BW: "+267-##-###-###",
  BY: "+375(##)###-##-##",
  BZ: "+501-###-####",
  CA: "+1(###)###-####",
  CD: "+243(###)###-###",
  CF: "+236-##-##-####",
  CG: "+242-##-###-####",
  CH: "+41-##-###-####",
  CI: "+225-##-###-###",
  CK: "+682-##-###",
  CL: "+56-#-####-####",
  CM: "+237-####-####",
  CN: "+86(###)####-####",
  CN: "+86(###)####-###",
  CN: "+86-##-#####-#####",
  CO: "+57(###)###-####",
  CR: "+506-####-####",
  CU: "+53-#-###-####",
  CV: "+238(###)##-##",
  CW: "+599-###-####",
  CY: "+357-##-###-###",
  CZ: "+420(###)###-###",
  DE: "+49(####)###-####",
  DE: "+49(###)###-####",
  DE: "+49(###)##-####",
  DE: "+49(###)##-###",
  DE: "+49(###)##-##",
  DE: "+49-###-###",
  DJ: "+253-##-##-##-##",
  DK: "+45-##-##-##-##",
  DM: "+1(767)###-####",
  DO: "+1(809)###-####",
  DO: "+1(829)###-####",
  DO: "+1(849)###-####",
  DZ: "+213-##-###-####",
  EC: "+593-##-###-####",
  EC: "+593-#-###-####",
  EE: "+372-####-####",
  EE: "+372-###-####",
  EG: "+20(###)###-####",
  ER: "+291-#-###-###",
  ES: "+34(###)###-###",
  ET: "+251-##-###-####",
  FI: "+358(###)###-##-##",
  FJ: "+679-##-#####",
  FK: "+500-#####",
  FM: "+691-###-####",
  FO: "+298-###-###",
  FR: "+262-#####-####",
  FR: "+33(###)###-###",
  FR: "+508-##-####",
  FR: "+590(###)###-###",
  GA: "+241-#-##-##-##",
  GD: "+1(473)###-####",
  GE: "+995(###)###-###",
  GF: "+594-#####-####",
  GH: "+233(###)###-###",
  GI: "+350-###-#####",
  GL: "+299-##-##-##",
  GM: "+220(###)##-##",
  GN: "+224-##-###-###",
  GQ: "+240-##-###-####",
  GR: "+30(###)###-####",
  GT: "+502-#-###-####",
  GU: "+1(671)###-####",
  GW: "+245-#-######",
  GY: "+592-###-####",
  HK: "+852-####-####",
  HN: "+504-####-####",
  HR: "+385-##-###-###",
  HT: "+509-##-##-####",
  HU: "+36(###)###-###",
  ID: "+62(8##)###-####",
  ID: "+62-##-###-##",
  ID: "+62-##-###-###",
  ID: "+62-##-###-####",
  ID: "+62(8##)###-###",
  ID: "+62(8##)###-##-###",
  IE: "+353(###)###-###",
  IL: "+972-5#-###-####",
  IL: "+972-#-###-####",
  IN: "+91(####)###-###",
  IO: "+246-###-####",
  IQ: "+964(###)###-####",
  IR: "+98(###)###-####",
  IS: "+354-###-####",
  IT: "+39(###)####-###",
  JM: "+1(876)###-####",
  JO: "+962-#-####-####",
  JP: "+81-##-####-####",
  JP: "+81(###)###-###",
  KE: "+254-###-######",
  KG: "+996(###)###-###",
  KH: "+855-##-###-###",
  KI: "+686-##-###",
  KM: "+269-##-#####",
  KN: "+1(869)###-####",
  KP: "+850-191-###-####",
  KP: "+850-##-###-###",
  KP: "+850-###-####-###",
  KP: "+850-###-###",
  KP: "+850-####-####",
  KP: "+850-####-#############",
  KR: "+82-##-###-####",
  KW: "+965-####-####",
  KY: "+1(345)###-####",
  KZ: "+7(6##)###-##-##",
  KZ: "+7(7##)###-##-##",
  LA: "+856(20##)###-###",
  LA: "+856-##-###-###",
  LB: "+961-##-###-###",
  LB: "+961-#-###-###",
  LC: "+1(758)###-####",
  LI: "+423(###)###-####",
  LK: "+94-##-###-####",
  LR: "+231-##-###-###",
  LS: "+266-#-###-####",
  LT: "+370(###)##-###",
  LU: "+352(###)###-###",
  LV: "+371-##-###-###",
  LY: "+218-##-###-###",
  LY: "+218-21-###-####",
  MA: "+212-##-####-###",
  MC: "+377(###)###-###",
  MC: "+377-##-###-###",
  MD: "+373-####-####",
  ME: "+382-##-###-###",
  MG: "+261-##-##-#####",
  MH: "+692-###-####",
  MK: "+389-##-###-###",
  ML: "+223-##-##-####",
  MM: "+95-##-###-###",
  MM: "+95-#-###-###",
  MM: "+95-###-###",
  MN: "+976-##-##-####",
  MO: "+853-####-####",
  MP: "+1(670)###-####",
  MQ: "+596(###)##-##-##",
  MR: "+222-##-##-####",
  MS: "+1(664)###-####",
  MT: "+356-####-####",
  MU: "+230-###-####",
  MV: "+960-###-####",
  MW: "+265-1-###-###",
  MW: "+265-#-####-####",
  MX: "+52(###)###-####",
  MX: "+52-##-##-####",
  MY: "+60-##-###-####",
  MY: "+60(###)###-###",
  MY: "+60-##-###-###",
  MY: "+60-#-###-###",
  MZ: "+258-##-###-###",
  NA: "+264-##-###-####",
  NC: "+687-##-####",
  NE: "+227-##-##-####",
  NF: "+672-3##-###",
  NG: "+234(###)###-####",
  NG: "+234-##-###-###",
  NG: "+234-##-###-##",
  NG: "+234(###)###-####",
  NI: "+505-####-####",
  NL: "+31-##-###-####",
  NO: "+47(###)##-###",
  NP: "+977-##-###-###",
  NR: "+674-###-####",
  NU: "+683-####",
  NZ: "+64(###)###-###",
  NZ: "+64-##-###-###",
  NZ: "+64(###)###-####",
  OM: "+968-##-###-###",
  PA: "+507-###-####",
  PE: "+51(###)###-###",
  PF: "+689-##-##-##",
  PG: "+675(###)##-###",
  PH: "+63(###)###-####",
  PK: "+92(###)###-####",
  PL: "+48(###)###-###",
  PS: "+970-##-###-####",
  PT: "+351-##-###-####",
  PW: "+680-###-####",
  PY: "+595(###)###-###",
  QA: "+974-####-####",
  RE: "+262-#####-####",
  RO: "+40-##-###-####",
  RS: "+381-##-###-####",
  RU: "+7(###)###-##-##",
  RW: "+250(###)###-###",
  SA: "+966-5-####-####",
  SA: "+966-#-###-####",
  SB: "+677-###-####",
  SB: "+677-#####",
  SC: "+248-#-###-###",
  SD: "+249-##-###-####",
  SE: "+46-##-###-####",
  SG: "+65-####-####",
  SH: "+290-####",
  SH: "+290-####",
  SI: "+386-##-###-###",
  SK: "+421(###)###-###",
  SL: "+232-##-######",
  SM: "+378-####-######",
  SN: "+221-##-###-####",
  SO: "+252-##-###-###",
  SO: "+252-#-###-###",
  SO: "+252-#-###-###",
  SR: "+597-###-####",
  SR: "+597-###-###",
  SS: "+211-##-###-####",
  ST: "+239-##-#####",
  SV: "+503-##-##-####",
  SX: "+1(721)###-####",
  SY: "+963-##-####-###",
  SZ: "+268-##-##-####",
  TC: "+1(649)###-####",
  TD: "+235-##-##-##-##",
  TG: "+228-##-###-###",
  TH: "+66-##-###-####",
  TH: "+66-##-###-###",
  TJ: "+992-##-###-####",
  TK: "+690-####",
  TL: "+670-###-####",
  TL: "+670-77#-#####",
  TL: "+670-78#-#####",
  TM: "+993-#-###-####",
  TN: "+216-##-###-###",
  TO: "+676-#####",
  TR: "+90(###)###-####",
  TT: "+1(868)###-####",
  TV: "+688-90####",
  TV: "+688-2####",
  TW: "+886-#-####-####",
  TW: "+886-####-####",
  TZ: "+255-##-###-####",
  UA: "+380(##)###-##-##",
  UG: "+256(###)###-###",
  UK: "+44-##-####-####",
  US: "+1(###)###-####",
  UY: "+598-#-###-##-##",
  UZ: "+998-##-###-####",
  VA: "+39-6-698-#####",
  VC: "+1(784)###-####",
  VE: "+58(###)###-####",
  VG: "+1(284)###-####",
  VI: "+1(340)###-####",
  VN: "+84-##-####-###",
  VN: "+84(###)####-###",
  VU: "+678-##-#####",
  VU: "+678-#####",
  WF: "+681-##-####",
  WS: "+685-##-####",
  YE: "+967-###-###-###",
  YE: "+967-#-###-###",
  YE: "+967-##-###-###",
  ZA: "+27-##-###-####",
  ZM: "+260-##-###-####",
  ZW: "+263-#-######"
}

export const tipoTabelas = [
  { label: 'TUSS', value: 'TUSS' },
  { label: 'AMB90', value: 'AMB90' },
  { label: 'AMB92', value: 'AMB92' },
  { label: 'AMB96', value: 'AMB96' },
  { label: 'AMB99', value: 'AMB99' },
  { label: 'CBHPM3', value: 'CBHPM3' },
  { label: 'CBHPM4', value: 'CBHPM4' },
  { label: 'CBHPM5', value: 'CBHPM5' },
  // { label: 'CBHPM2010', value: 'CBHPM2010' },
  // { label: 'CBHPM2012', value: 'CBHPM2012' },
  // { label: 'CBHPM2014', value: 'CBHPM2014' },
  // { label: 'CBHPM2016', value: 'CBHPM2016' },
  // { label: 'CBHPM2018', value: 'CBHPM2018' },
  // { label: 'CBHPM2020', value: 'CBHPM2020' },
  // { label: 'SUS', value: 'SUS' },
  { label: 'PRÓPRIA', value: 'PRÓPRIA' }
]