import { getReq, postReq, putReq, sendFile } from "@services/api"

export const profissional = {
    getById: async (id) => { return await getReq('profissional', `/cadastros/profissional/${id}`, true) },
    getByClientId: async (idCliente) => { return await getReq('profissional', `/cadastros/profissionais/${idCliente}`, true) },
    update: async (id, body) => { return await putReq('profissional', `/cadastros/profissional/${id}`, body, true) },
    create: async (body) => { return await postReq('profissional', `/cadastros/profissional`, body, true) },
    index: async (clientId, search, pageNumber, pageSize) => { return await getReq('profissional', `/cadastros/profissional?ClienteId=${clientId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    enableDisabled: async (id, situacao) => { return await putReq('profissional', `/cadastros/profissional/${id}/situacao?situacao=${situacao}`, null, true) },
    tornarPadrao: async (previousId, id) => { return await getReq('profissional', `/cadastros/profissional-executante/${previousId}/${id}`, true) }
}

export const usuario = {
    getById: async (id) => { return await getReq('usuario', `/usuarios/obter-usuario/${id}`, true) },
    getByIdUserContext: async (id) => { return await getReq('usuario', `/cadastros/usuario/${id}`, true) },
    // nenhuma referência 
    // getUnities: async (usuarioId) => { return await getReq('unidades', `/cadastros/usuario-unidade?usuarioId=${usuarioId}`, true) },
    update: async (id, body) => { return await putReq('usuario', `/usuarios/alterar-usuario/${id}`, body, true) },
    // update: async (id, body) => { return await putReq('usuario', `/cadastros/usuario/${id}`, body, true) },
    // update no my profile não muda a controller
    updateMyProfile: async (id, body) => { return await putReq('usuario', `/cadastros/usuario/meu-perfil/${id}`, body, true) },
    create: async (body) => { return await postReq('usuario', `/usuarios/new-usuario`, body, true) },
    // create: async (body) => { return await postReq('usuario', `/cadastros/usuario`, body, true) },
    index: async (clienteId, search, pageNumber, pageSize) => { return await getReq('usuario', `/usuarios/usuarios-clinica?ClienteId=${clienteId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    // index: async (clienteId, unidadeLogadaId, search, pageNumber, pageSize) => { return await getReq('usuario', `/cadastros/usuario?clienteId=${clienteId}&unidadeLogadaId=${unidadeLogadaId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    enableDisabled: async (id, situacao) => { return await getReq('usuario', `/usuarios/usuario-situacao/${id}/${situacao}`, true) },
    // enableDisabled: async (id, situacao) => { return await putReq('usuario', `/cadastros/usuario/${id}/situacao?situacao=${situacao}`, null, true) },
    // delete_foto não muda a controller
    delete_foto: async (usuarioUnidadeId) => { return await getReq('unidades', `/cadastros/excluir-foto-usuario/${usuarioUnidadeId}`, true) },
    claims: async () => { return await getReq('usuario', `/usuarios/claims`, true) }
}

export const operador = {
    getById: async (id) => { return await getReq('operadoras', `/cadastros/operadora-cliente/${id}`, true) },
    getByClientId: async (idCliente) => { return await getReq('operadoras', `/cadastros/operadora-cliente-list/${idCliente}`, true) },
    conveniosCredenciais: async (idCliente) => { return await getReq('operadoras', `/cadastros/operadora-credenciais-cliente-list/${idCliente}`, true) },
    update: async (id, body) => { return await putReq('operadoras', `/cadastros/operadora-cliente/${id}`, body, true) },
    create: async (body) => { return await postReq('operadoras', `/cadastros/operadora-cliente`, body, true) },
    createNew: async (body) => { return await postReq('operadoras', `/cadastros/operadora-cliente-new`, body, true) },
    index: async (clientId, search, pageNumber, pageSize) => { return await getReq('operadoras', `/cadastros/operadora-cliente?ClienteId=${clientId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    clientIndex: async (clientId) => { return await getReq('operadoras', `/cadastros/operadora-cliente-list/${clientId}`, true) },
    enableDisabled: async (id, situacao) => { return await putReq('operadoras', `/cadastros/operadora-cliente/${id}/situacao?situacao=${situacao}`, null, true) },
    addProcTabelaPropria: async (body) => { return await postReq('operadoras', `/cadastros/procedimento-tabela-propria`, body, true) },
    ativarDesativarProcedimentoTabelaPropria: async (id, bool) => { return await getReq('operadoras', `/cadastros/situacao-procedimento-tabela-propria/${id}/${bool}`, true) },
    listarOperadorasCliente: async (clientId, search, pageNumber, pageSize) => { return await getReq('operadoras', `/cadastros/listar-operadoras-cliente?ClienteId=${clientId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}

export const diariasTaxas = {
    getById: async (id) => { return await getReq('diariaTaxa', `/cadastros/diaria-taxa/${id}`, true) },
    create: async (body) => { return await postReq('diariaTaxa', `/cadastros/diaria-taxa`, body, true) },
    update: async (body) => { return await putReq('diariaTaxa', `/cadastros/diaria-taxa/atualizar`, body, true) },
    getByClientId: async (idCliente, idConvenio, busca) => { return await getReq('diariaTaxa', `/cadastros/diaria-taxa/listar?ClienteId=${idCliente}&Busca=${busca}&OperadoraId=${idConvenio}`, true) },
    index: async (clientId, search, pageNumber, pageSize) => { return await getReq('diariaTaxa', `/cadastros/diaria-taxa?ClienteId=${clientId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}

export const pacotes = {
    getById: async (id) => { return await getReq('pacotes', `/cadastros/pacote/${id}`, true) },
    getByClientId: async (idCliente, idConvenio, busca) => { return await getReq('pacotes', `/cadastros/pacotes-cliente?ClienteId=${idCliente}&Busca=${busca}&OperadoraId=${idConvenio}`, true) },
    update: async (id, body) => { return await putReq('pacotes', `/cadastros/pacote/${id}/atualizar`, body, true) },
    create: async (body) => { return await postReq('pacotes', `/cadastros/pacote`, body, true) },
    enableDisabled: async (id, situacao) => { return await getReq('pacotes', `/cadastros/pacote/${id}/situacao/${situacao}`, true) },
    index: async (clientId, search, pageNumber, pageSize) => { return await getReq('pacotes', `/cadastros/pacotes?ClienteId=${clientId}&Busca=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}

export const tabelas_preco = {
    list: async (clienteId) => { return await getReq('tabelas de preço', `/TabelaPreco/listar?clienteId=${clienteId}`, true) },
    index: async (clienteId, search, pageNumber, pageSize) => { return await getReq('tabelas de preço', `/TabelaPreco/listar?clienteId=${clienteId}&search=${search}&page=${pageNumber}&take=${pageSize}`, true) },
    update: async (body) => { return await putReq('tabela de preço', `/TabelaPreco/atualizar`, body, true) },
    create: async (body) => { return await postReq('tabela de preço', `/TabelaPreco/salvar`, body, true) },
    importFile: async (file) => { return await sendFile('tabela de preço', `/TabelaPreco/importar-procedimentos`, file, true) },
    enableDisabled: async (id, situacao) => { return await putReq('tabela de preço', `/TabelaPreco/${id}/situacao?situacao=${situacao}`, null, true) }
}

export const unidades = {
    index: async (clienteId, search, pageNumber, pageSize) => { return await getReq('unidades', `/cadastros/unidades?clienteId=${clienteId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    update: async (id, body) => { return await putReq('unidades', `/cadastros/unidades/${id}/atualizar`, body, true) },
    create: async (body) => { return await postReq('unidades', `/cadastros/unidades`, body, true) },
    enableDisabled: async (id, situacao) => { return await putReq('unidades', `/cadastros/unidades/${id}/situacao?situacao=${situacao}`, null, true) },
    unidadePrincipal: async (id, clienteId, usuarioId) => { return await getReq('unidades', `/cadastros/alterar-unidade-padrao/${id}/${clienteId}/${usuarioId}`, true) },
    getAll: async (clienteId) => { return await getReq('unidades', `/cadastros/unidades-cliente?clienteId=${clienteId}`, true) },
    getUsuarioUnidade: async (usuarioId) => { return await getReq('unidades', `/cadastros/lista-usuario-unidade?usuarioId=${usuarioId}`, true) }
}

export const ponto_atendimento = {    
    updateSituacao: async (pontoAtendimentoId, situacao) => { return await putReq('pontoAtendimento', `/cadastros/situacao/${pontoAtendimentoId}/${situacao}/ponto-atendimento`, null, true) },
    updatePontoAtendimento: async (pontoAtendimentoId, body) => { return await putReq('pontoAtendimento', `/cadastros/${pontoAtendimentoId}/ponto-atendimento`, body, true) },
    createPontoAtendimento: async (body) => { return await postReq('pontoAtendimento', `/cadastros/ponto-atendimento`, body, true) }
}

export const pacientes = {
  index: async (search, clienteId, pageNumber, pageSize) => { return await getReq('pacientes', `/pacientes/listar?Busca=${search}&clienteId=${clienteId}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
  getById: async (id) => { return await getReq('pacientes', `/pacientes/obter/${id}`, true) },
  create: async (body) => { return await postReq('pacientes', '/pacientes/salvar', body, true) },
  update: async (id, body) => { return await putReq('pacientes', `/pacientes/atualizar/${id}`, body, true) },
  addBiometria: async (body) => { return await postReq('pacientes', '/pacientes/biometria', body, true) },
  removeDedoBiometria: async (id, dedo) => { return await getReq('pacientes', `/pacientes/biometria-excluir?pacienteId=${id}&dedo=${dedo}`, true) },
  elegibilidade: async (body) => { return await postReq('pacientes', `/pacientes/elegibilidade`, body, true) }
}

export const operadoras = {
    obterOperadorasSemPendencias: async (operadora, pageNumber, pageSize) => { return await getReq('operadoras', `/cadastros/operadoras-sem-pendencias?Operadora=${operadora}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}